import React from 'react';
import { Layout, SEO } from 'components/common';

export default () => (
  <Layout>
    <SEO title="404: Stran ne obstaja" location="/404" />
    <h1>Stran ne obstaja</h1>
    <p>
      Prišli ste na stran, ki ne obstaja. Pojdite nazaj na <a href="/">prvo stran</a>
    </p>
  </Layout>
);
